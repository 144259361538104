import Time from "./Time";
import Sizes from "./Sizes";
import Mouse from "./Mouse";
import Render from "./Render";
import Camera from "./Camera";
import Passes from "./Passes";
import AnimationController from "./AnimationController";
import AudioManager from "./AudioManager";

export default {
  Time,
  Sizes,
  Mouse,
  Render,
  Camera,
  Passes,
  AnimationController,
  AudioManager
};
