import ClassUtils from "./ClassUtils";

export default class EventEmitter extends ClassUtils {
  /**
   * Constructor
   */
  constructor(name = "EventEmitter") {
    super(name);

    this._callbacks = {};
  }

  /**
   * On
   */
  on(eventName, callback) {
    if (typeof callback !== "function") {
      return this._commitError("callback must be a function!");
    }

    if (!this._callbacks[eventName]) {
      this._callbacks[eventName] = [];
    }

    this._callbacks[eventName].push(callback);
  }

  /**
   * Off
   */
  off(eventName) {
    this._callbacks[eventName] = [];
  }

  /**
   * Trigger
   */
  trigger(eventName, event) {
    if (this._callbacks[eventName]) {
      this._callbacks[eventName].forEach(callback => callback(event));
    }
  }
}
