import ClassUtils from "../Utils/ClassUtils";

export default class AudioManager extends ClassUtils {
  /**
   * Constructor
   */
  constructor() {
    super("AudioManager");

    this._$background = document.querySelector("audio.audio--background");
    if (this._$background) {
      this._$background.volume = 0.7;
    }
    this._$click = document.querySelector("audio.audio--click");
    if (this._$click) {
      this._$click.volume = 0.4;
    }

    // this._$clickables = document.querySelectorAll("[data-click]");
    // for (const $clickable of this._$clickables) {
    //   $clickable.addEventListener("mouseenter", this.click.bind(this));
    //   $clickable.addEventListener("touchstart", this.click.bind(this));
    // }
    document.addEventListener("click", this.click.bind(this));

    this._started = false;
  }

  play() {
    this._started = true;
    if (this._$background) {
      this._$background.play();
    }
  }

  pause() {
    if (this._$background) {
      this._$background.pause();
    }
  }

  stop() {
    if (this._$background) {
      this._$background.stop();
    }
  }

  click() {
    if (this._$click && this._started) {
      this._$click.play();
    }
  }
}
