import ClassUtils from "../Utils/ClassUtils";
import timingFunctions from "../Utils/timingFunctions";

import fetch from "isomorphic-unfetch";
import {
  Group,
  Mesh,
  TetrahedronBufferGeometry,
  MeshPhongMaterial,
  Color,
  Raycaster,
  Vector2
} from "three";

export default class WishManager extends ClassUtils {
  constructor(ctx) {
    super("WishManager");

    this._ctx = ctx;

    this.init(ctx);
  }

  init(ctx) {
    const wishes = new Group();
    wishes.position.set(0, 450, 0);
    ctx.world.world.add(wishes);
    const raycaster = new Raycaster();

    const $formSlide = ctx._$container.querySelector(".slide--form");
    const $form = $formSlide.querySelector("form.wish");
    const $message = $form.querySelector("textarea#message");
    const $author = $form.querySelector("input#author");
    const $seeWishes = $formSlide.querySelector(".seeWishes");
    const $sendWish = $formSlide.querySelector(".sendWish");

    const $wishesContainer = ctx._$container.querySelector(".wishesContainer");
    const $shadow = ctx._$container.querySelector(".shadow");

    // Events
    this._canSubmit = true;
    $form.addEventListener("submit", e => {
      e.preventDefault();

      this.handleSubmit();
    });

    $seeWishes.addEventListener("click", () => {
      $formSlide.classList.add("see");
      $formSlide.classList.remove("send");
      $wishesContainer.classList.add("see");
      $wishesContainer.classList.remove("send");
      $shadow.classList.add("disabled");
    });
    $sendWish.addEventListener("click", () => {
      $formSlide.classList.add("send");
      $formSlide.classList.remove("see");
      $wishesContainer.classList.add("send");
      $wishesContainer.classList.remove("see");
      $shadow.classList.remove("disabled");
    });

    ctx.time.on("tick", this.tick.bind(this));

    // Saving
    this._lang = document.documentElement.lang;
    this._loaded = false;

    this._wishes = wishes;
    this._wishesData = {};
    this._raycaster = raycaster;
    this._intersected = undefined;
    this._intersects = {};
    this._duration = 900;

    this._$formSlide = $formSlide;
    this._$form = $form;
    this._$message = $message;
    this._$author = $author;
    this._$seeWishes = $seeWishes;
    this._$sendWish = $sendWish;

    this._$wishesContainer = $wishesContainer;
    this._$shadow = $shadow;

    this.updateSubmitStatus("unsubmitted");
    this._commitInfo("inited");
  }

  start() {
    this.getRandomWishes();
  }

  async getRandomWishes() {
    try {
      const response = await fetch(
        `${process.env.API_URL}/wish?lang=${this._lang}`
      );
      if (response.ok) {
        const { data } = await response.json();

        setTimeout(() => {
          this.displayWishes(data);
        }, 1500);

        this._loaded = true;
      } else {
        throw response;
        // TODO: Handle error
      }
    } catch (error) {
      this._commitError("While getting random wishes:", error);
    }
  }

  async handleSubmit() {
    if (!this._canSubmit) {
      return;
    }
    this._canSubmit = false;

    this.updateSubmitStatus("submitting");

    const submitSuccess = await this.sendWish({
      message: this._$message.value,
      author: this._$author.value
    });

    setTimeout(() => {
      this.updateSubmitStatus("submitted");

      if (submitSuccess) {
        setTimeout(() => {
          this._$message.value = "";
          this._$author.value = "";
        }, 1000);
      }
    }, 1000);

    setTimeout(() => {
      this._canSubmit = true;
    }, 5000);
  }

  async sendWish({ message, author } = {}) {
    if (!message) {
      return;
    }

    if (!author) {
      author = {
        en: "Anonymous",
        fr: "Anonyme"
      }[this._lang];
    }

    try {
      const response = await fetch(`${process.env.API_URL}/wish`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          author,
          message,
          lang: this._lang
        })
      });

      if (response.ok) {
        return true;
      } else {
        throw response;
        // TODO: Handle error
      }
    } catch (error) {
      this._commitError("While posting wish:", error);
      return false;
    }
  }

  displayWishes(wishes) {
    let i = 0;
    let angle = 0;
    const radius = 150;
    const anglePortion = 360 / wishes.length;

    for (const wish of wishes) {
      const mesh = new Mesh(
        new TetrahedronBufferGeometry(
          8 + Math.floor(Math.random() * 3),
          Math.floor(Math.random() * 2)
        ),
        new MeshPhongMaterial({
          color: new Color(`hsl(${Math.random() * 360}, 70%, 35%)`),
          flatShading: true
        })
      );

      angle += anglePortion;
      const dist = 1 + Math.random() * 0.6;

      mesh.position.set(
        radius * dist * Math.cos(angle),
        radius * dist * Math.sin(angle),
        -150 * Math.random() + 50
      );
      mesh.rotation.set(Math.random(), Math.random(), Math.random());
      mesh.scale.set(0.001, 0.001, 0.001);
      this._intersects[mesh.uuid] = 0;
      this._wishesData[mesh.uuid] = wish;

      setTimeout(() => {
        this._wishes.add(mesh);
        this._ctx.animationController.add({
          mesh,
          scale: [1, 1, 1],
          duration: 1200,
          timingFunction: "easeInOutQuint",
          strategy: "to"
        });
      }, 100 * i++);
    }
  }

  updateSubmitStatus(newStatus) {
    this._$form.classList.add(newStatus);
    if (this._submitStatus) {
      this._$form.classList.remove(this._submitStatus);
    }
    this._submitStatus = newStatus;
  }

  tick({ elapsed, delta }) {
    this._wishes.position.set(0, 450 + Math.sin(elapsed / 2000) * 20, 0);
    this._wishes.rotation.set(0, 0, elapsed / 12000);

    // Deal with satellite hover
    const { x, y } = this._ctx.mouse;

    this._raycaster.setFromCamera(new Vector2(x, -y), this._ctx.camera.get());

    const intersects = this._raycaster.intersectObjects(this._wishes.children);

    if (intersects.length) {
      if (this._intersected !== intersects[0].object.uuid) {
        this._intersected = intersects[0].object.uuid;
        this._ctx.audioManager.click();
        this.updateDisplayedWish(this._wishesData[this._intersected]);
      }
      if (!document.documentElement.classList.contains("pointer")) {
        document.documentElement.classList.add("pointer");
      }
    } else {
      document.documentElement.classList.remove("pointer");
    }

    for (const wish of this._wishes.children) {
      let needUpdate = false;
      if (wish.uuid == this._intersected) {
        if (this._intersects[wish.uuid] <= this._duration) {
          this._intersects[wish.uuid] += delta;
          this._intersects[wish.uuid] = Math.min(
            this._intersects[wish.uuid],
            this._duration
          );
          needUpdate = true;
        }
      } else if (this._intersects[wish.uuid] > 0) {
        this._intersects[wish.uuid] -= delta * 0.7;
        this._intersects[wish.uuid] = Math.max(this._intersects[wish.uuid], 0);
        needUpdate = true;
      }

      if (needUpdate) {
        let ratio =
          1 +
          timingFunctions.easeInOutQuint(
            this._intersects[wish.uuid] / this._duration
          ) *
            1.5;

        ratio += Math.sin(elapsed / 1000) * 0.5 * (ratio - 1);

        wish.scale.set(ratio, ratio, ratio);
      }
    }
  }

  updateDisplayedWish({ author, message }) {
    const $oldWishes = this._$wishesContainer.querySelectorAll(".wish");
    const $wish = document.createElement("div");
    $wish.classList.add("wish");
    $wish.innerHTML =
      '<div class="no-overflow"><div class="message content text-p"></div></div><div class="no-overflow"><div class="author content text-h3 text-right"></div></div>'; // eslint-disable-line

    this._$wishesContainer.appendChild($wish);

    // Display message
    const $message = $wish.querySelector(".message");
    $message.innerText = message;

    // Display author
    const $author = $wish.querySelector(".author");
    if (["anonyme", "anonymous"].includes(author.toLowerCase())) {
      $author.innerText = {
        en: "Anonymous wish",
        fr: "Vœu anonyme"
      }[this._lang];
    } else {
      $author.innerText = `${
        {
          en: "A wish from",
          fr: "Un vœu de"
        }[this._lang]
      } ${author}`;
    }

    // Toggle display
    for (const $oldWish of $oldWishes) {
      $oldWish.classList.add("leaving");
      $oldWish.classList.remove("entering");
    }
    $wish.classList.add("active");
    setTimeout(() => {
      for (const $oldWish of $oldWishes) {
        $oldWish.remove();
      }

      $wish.classList.add("entering");
    }, 375 * Math.min($oldWishes.length, 1));
  }
}
