import BrowserDetector from "./Utils/BrowserDetector";

export default class Detect {
  constructor() {
    this.browser = "";
    this.mobile = false;
    this.touch = false;

    this.init();
  }

  init() {
    const isMobile = require("is-mobile");

    // Browser?
    const browserDetection = new BrowserDetector();
    this.browser = browserDetection.browser;

    // Mobile?
    if (isMobile()) {
      this.mobile = true;
    }

    // Touch?
    const that = this;
    window.addEventListener(
      "touchstart",
      function onFirstTouch(e) {
        e.stopPropagation();
        that.touch = true;
        window.removeEventListener("touchstart", onFirstTouch, false);
      },
      false
    );
  }
}

window.detect = new Detect();

if (window.detect.browser && window.detect.browser === "explorer") {
  const $ieScreen = document.querySelector(".slide--ie");
  $ieScreen.classList.add("active");
  setTimeout(() => {
    $ieScreen.classList.add("entering");
  }, 100);
}
