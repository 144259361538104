/**
 * Debounce from Underscore.js
 * @param {function} func - function to debounce
 * @param {number} wait - delay
 * @param {boolean} immediate - if true call function on the leading edge instead of the trailing
 * @return {function}
 */
const debounce = function(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export default debounce;
