import EventEmitter from "../Utils/EventEmitter";

import { Object3D } from "three";

export default class Scenery extends EventEmitter {
  constructor(ctx, { planet, slide = "" } = {}) {
    super("Scenery");

    this._ctx = ctx;

    this._planet = !!planet ? planet.clone() : false;
    this._slide = slide;

    this.init(ctx);
  }

  init(ctx) {
    // Definition
    const $slide = ctx._$container.querySelector(this._slide);
    const $cta = $slide.querySelector(".cta");
    if (!!$cta) {
      $cta.addEventListener("click", this.die.bind(this), { once: true });
      this._$cta = $cta;
    } else {
      this._$cta = false;
    }

    if (this._planet) {
      const planetHolder = new Object3D();
      planetHolder.position.copy(this._planet.position.clone());
      this._planet.position.set(0, 0, 0);
      planetHolder.scale.set(0.001, 0.001, 0.001);

      planetHolder.add(this._planet);
      ctx.world.world.add(planetHolder);

      this._planetHolder = planetHolder;
    } else {
      this._planetHolder = false;
    }

    // Saving
    this._$slide = $slide;

    this._commitInfo("inited");
  }

  start() {
    if (this._planetHolder) {
      this._ctx.animationController.add({
        mesh: this._planetHolder,
        scale: [1, 1, 1],
        duration: 2000,
        timingFunction: "easeInOutQuint",
        strategy: "to"
      });
    }

    setTimeout(() => {
      this._$slide.classList.add("active", "entering");

      if (this._$cta) {
        setTimeout(() => {
          this._$cta.classList.add("active");
        }, 1000);
      }
    }, 500);
  }

  tick(elapsed, frame) {
    if (this._planet) {
      this._planet.rotation.set(0, elapsed / 12000, elapsed / 7000);
    }
  }

  die() {
    this.trigger("dying");

    this._$slide.classList.add("leaving");
    this._$slide.classList.remove("entering");

    if (this._$cta) {
      this._$cta.classList.remove("active");
    }

    if (this._planetHolder) {
      this._ctx.animationController.add({
        mesh: this._planetHolder,
        scale: [0.001, 0.001, 0.001],
        duration: 1500,
        timingFunction: "easeInOutQuint",
        strategy: "to"
      });
    }

    setTimeout(() => {
      this._$slide.classList.remove("active", "leaving");
      if (this._planet) {
        this._ctx.scene.remove(this._planet);
      }

      this.trigger("died");
    }, 1500);
  }
}
