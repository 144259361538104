import ClassUtils from "./ClassUtils";
import EventEmitter from "./EventEmitter";
import BrowserDetector from "./BrowserDetector";

import debounce from "./debounce";
import throttle from "./throttle";

export default {
  ClassUtils,
  EventEmitter,
  BrowserDetector,

  debounce,
  throttle
};
