import "core-js/stable";
import "regenerator-runtime/runtime";

import Application from "./Application.js";

if (!window.detect || window.detect.browser !== "explorer") {
  window.application = new Application(document.querySelector(".application"));

  if (module.hot) {
    module.hot.dispose(() => {
      window.application.destructor();
      window.application = null;
    });
  }
}
