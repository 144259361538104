import ClassUtils from "../Utils/ClassUtils";

import {
  Object3D,
  Mesh,
  AmbientLight,
  DirectionalLight,
  MeshPhongMaterial,
  IcosahedronBufferGeometry,
  Points,
  Vector3,
  Geometry,
  PointsMaterial,
  MeshBasicMaterial
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import Scenery from "./Scenery";
import WishManager from "./WishManager";

export default class World extends ClassUtils {
  constructor(ctx) {
    super("World");

    this._ctx = ctx;

    this.init(ctx);
  }

  init(ctx) {
    // World
    this.world = new Object3D();
    this._ctx.scene.add(this.world);

    // Common objects
    this.createSun(ctx);
    this.createStars(ctx);
    const $loadingScreen = ctx._$container.querySelector(".slide--loading");
    $loadingScreen.classList.add("active", "entering");

    // Lighting
    ctx.scene.add(new AmbientLight(0xffddaa, 3));
    const directionaLight = new DirectionalLight(0xffddaa, 2);
    directionaLight.position.set(-1, 0.7, 0.15);
    ctx.scene.add(directionaLight);

    // Camera
    ctx.camera.setTargetPosition(-50, 200, 0);
    ctx.camera.setPosition(0, 100, 0);

    // Events
    ctx.time.on("tick", ({ elapsed, frame }) => {
      if (this.activeScenery && this.activeScenery.tick) {
        this.activeScenery.tick(elapsed, frame);
      }
    });

    // Saving
    this.activeScenery = null;
    this._$loadingScreen = $loadingScreen;

    this._commitInfo("inited");
  }

  createSun(ctx) {
    const sun = new Object3D();

    const inner = new Mesh(
      new IcosahedronBufferGeometry(40, 1),
      new MeshPhongMaterial({
        color: 0xffc428,
        transparent: true,
        flatShading: true,
        emissive: 0xffc428,
        emissiveIntensity: 0.8
      })
    );
    const outer = new Mesh(
      new IcosahedronBufferGeometry(50, 1),
      new MeshBasicMaterial({
        color: 0xffc773,
        flatShading: true,
        transparent: true,
        opacity: 0.2
      })
    );

    inner.frustumCulled = false;

    ctx.time.on("tick", ({ elapsed, frame }) => {
      elapsed /= 5000;

      const ratio = 1 + Math.sin(elapsed) * 0.1;
      inner.scale.set(ratio, ratio, ratio);
      outer.rotation.set(0, elapsed / 8, elapsed / 4);
      sun.rotation.set(0, elapsed / 2, elapsed);
    });

    sun.add(inner);
    sun.add(outer);

    this.world.add(sun);

    // Saving
    this.sun = { object: sun, lightSource: outer };
  }

  createStars(ctx) {
    const geometry = new Geometry();

    for (let i = 0; i < 1000; i++) {
      const v3 = new Vector3(
        Math.random() - 0.5,
        Math.random() - 0.5,
        Math.random() - 0.5
      );
      v3.normalize();
      v3.multiplyScalar(600 + Math.random() * 800);

      geometry.vertices.push(v3);
    }

    const stars = new Points(
      geometry,
      new PointsMaterial({
        color: 0xffe448
      })
    );

    this.world.add(stars);
  }

  load(ctx) {
    const loader = new GLTFLoader();
    this.models = {};

    const modelFiles = {
      desert: {
        file: "/assets/models/desert.glb",
        callback(model) {
          model.scale.set(1.3, 1.3, 1.3);
          model.position.set(0, 400, 0);
        }
      },
      sakura: {
        file: "/assets/models/sakura.glb",
        callback(model) {
          model.scale.set(2, 2, 2);
          model.position.set(0, 400, 0);
        }
      },
      winter: {
        file: "/assets/models/winter.glb",
        callback(model) {
          model.scale.set(0.825, 0.825, 0.825);
          model.position.set(0, 400, 0);
        }
      },
      volcano: {
        file: "/assets/models/volcano.glb",
        callback(model) {
          model.scale.set(1.25, 1.25, 1.25);
          model.position.set(0, 400, 0);
        }
      }
    };

    const loadedCallback = () => {
      for (const name in modelFiles) {
        const model = modelFiles[name];
        if (!model.loaded) return;
      }

      setTimeout(() => {
        this.loaded(ctx);
      }, 2000);
    };

    for (const name in modelFiles) {
      const model = modelFiles[name];
      model.loaded = false;
      loader.load(model.file, data => {
        if (model.callback) {
          model.callback(data.scene);
        }
        this.models[name] = data.scene;
        model.loaded = true;

        loadedCallback();
      });
    }
  }

  loaded(ctx) {
    this._$loadingScreen.classList.add("loaded");
    this._$loadingScreen.classList.remove("entering");
    this._$loadingScreen.querySelector(".text--loaded").addEventListener(
      "click",
      () => {
        ctx.animationController.add({
          mesh: ctx.camera.target,
          position: [0, 400, 0],
          duration: 3000,
          timingFunction: "easeInOutQuint",
          strategy: "to"
        });

        this._$loadingScreen.classList.add("leaving");
        this._$loadingScreen.classList.remove("loaded");

        this._ctx.audioManager.play();

        setTimeout(() => {
          this._$loadingScreen.classList.remove("active", "leaving");
          this.desertScene(ctx);
        }, 1500);
      },
      { once: true }
    );

    this._commitInfo("loaded");
  }

  quickLoad(ctx) {
    ctx.animationController.add({
      mesh: ctx.camera.target,
      position: [0, 400, 0],
      duration: 3000,
      timingFunction: "easeInOutQuint",
      strategy: "to"
    });

    this._$loadingScreen.classList.add("leaving");
    this._$loadingScreen.classList.remove("loaded");

    this._$loadingScreen.classList.remove("active", "leaving");
    this.finalScene(ctx);
  }

  // 1
  desertScene(ctx) {
    this.activeScenery = new Scenery(ctx, {
      planet: this.models.desert,
      slide: ".slide--1"
    });

    this.activeScenery.start();

    this.activeScenery.on("died", this.sakuraScene.bind(this, ctx));
  }

  // 2
  sakuraScene(ctx) {
    this.activeScenery = new Scenery(ctx, {
      planet: this.models.sakura,
      slide: ".slide--2"
    });

    this.activeScenery.start();

    this.activeScenery.on("died", this.winterScene.bind(this, ctx));
  }

  winterScene(ctx) {
    this.activeScenery = new Scenery(ctx, {
      planet: this.models.winter,
      slide: ".slide--3"
    });

    this.activeScenery.start();

    this.activeScenery.on("died", this.volcanoScene.bind(this, ctx));
  }

  volcanoScene(ctx) {
    this.activeScenery = new Scenery(ctx, {
      planet: this.models.volcano,
      slide: ".slide--4"
    });

    this.activeScenery.start();

    this.activeScenery.on("died", this.finalScene.bind(this, ctx));
  }

  finalScene(ctx) {
    this.activeScenery = new Scenery(ctx, {
      slide: ".slide--form"
    });
    this.activeScenery.start();

    const earth = new Mesh(
      new IcosahedronBufferGeometry(60, 2),
      new MeshPhongMaterial({
        color: 0x579957,
        transparent: true,
        flatShading: true
      })
    );
    earth.position.set(0, 150, 70);
    ctx.camera.gimbal.add(earth);

    ctx.animationController.add({
      mesh: ctx.camera.get(),
      position: [0, 200, 0],
      duration: 2000,
      timingFunction: "easeInOutQuint",
      strategy: "to"
    });

    const wishManager = new WishManager(ctx);

    wishManager.start();
  }
}
